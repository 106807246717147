<template>
  <div class="van-main has-fixed-btn">
    <div class="van-common-detail">
      <van-tabs v-model="activeName">
        <van-tab name="info" title="转运信息">
          <van-cell-group title="提单信息">
            <van-cell title="提单号" :value="utils.isEffectiveCommon(transportForm.pickUpNo)" />
            <van-cell title="合同号（PI/SC）" :value="utils.isEffectiveCommon(transportForm.logisticsBscInfoVo.pi)" />
            <van-cell title="订单名称" :value="utils.isEffectiveCommon(transportForm.logisticsBscInfoVo.orderName)" />
            <van-cell title="采购合同" :value="utils.isEffectiveCommon()" />
            <van-cell title="订单编号" :value="utils.isEffectiveCommon(transportForm.logisticsBscInfoVo.orderNo)" />
          </van-cell-group>
          <van-cell-group v-for="item in _.get(transportForm, 'logisticsBscInfoVo.logisticsPickUpInfoVoList', [])" :key="item.id">
            <div class="van-common-right">
              <van-cell title="合同号（PI/SC）：" :value="utils.isEffectiveCommon(item.pi)" />
              <van-cell title="柜号：" :value="utils.isEffectiveCommon(item.cabinetNo)" />
              <van-cell title="国家：" :value="utils.isEffectiveCommon(item.country)" />
              <van-cell title="原产地：" :value="utils.isEffectiveCommon(item.countryOfOrigin)" />
              <van-cell title="HS编码：" :value="utils.isEffectiveCommon(item.hsCode)" />
              <van-cell title="货物名称：" :value="utils.isEffectiveCommon(item.cargoName)" />
              <van-cell title="箱型：" :value="utils.statusFormat(item.boxType, 'Box_Type')" />
              <van-cell title="件数：" :value="utils.isEffectiveCommon(item.pieceNumber)" />
              <van-cell title="实发重量(KG)：" :value="utils.isEffectiveCommon(item.actualWeight)" />
              <van-cell title="原币总金额：" :value="utils.moneyFormat(item.money)" />
            </div>
          </van-cell-group>
          <van-cell-group title="船运信息">
            <van-cell title="船运公司" :value="utils.isEffectiveCommon(transportForm.logisticsBscInfoVo.shippingCompany)" />
            <van-cell title="船名航次" :value="utils.isEffectiveCommon(transportForm.logisticsBscInfoVo.oceanVesselVoyNo)" />
            <van-cell title="报关行名称" :value="utils.isEffectiveCommon(transportForm.logisticsBscInfoVo.customsBrokerName)" />
            <van-cell title="起运地" :value="utils.isEffectiveCommon(transportForm.logisticsBscInfoVo.originPlace)" />
            <van-cell title="装运口岸" :value="utils.isEffectiveCommon(transportForm.logisticsBscInfoVo.shipmentPort)" />
            <van-cell title="目的地" :value="utils.isEffectiveCommon(transportForm.logisticsBscInfoVo.destination)" />
            <van-cell title="目的口岸" :value="utils.isEffectiveCommon(transportForm.logisticsBscInfoVo.destinationPort)" />
            <van-cell title="申报口岸" :value="utils.isEffectiveCommon(transportForm.logisticsBscInfoVo.entryPort)" />
            <van-cell title="预计到港日期" :value="utils.dateFormat(transportForm.logisticsBscInfoVo.expectedArrivalTime)" />
          </van-cell-group>
          <van-cell-group title="转运信息">
            <van-cell title="船运公司" :value="utils.isEffectiveCommon(transportForm.bond)" />
            <van-cell title="转运口岸" :value="utils.isEffectiveCommon(transportForm.surplus)" />
            <van-cell title="转运单" :value="utils.isEffectiveCommon()" />
          </van-cell-group>
          <van-cell-group title="提单副本材料">
            <div class="table goods-table">
              <table class="inner-goods-table">
                <thead>
                  <tr>
                    <th>证明材料名称</th>
                    <th>状态</th>
                    <th>附件</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in transportForm.logisticsBscInfoVo.commonLicenceInfoVoList" :key="item.materialId">
                    <td>{{ utils.isEffectiveCommon(item.licenceName) }}</td>
                    <td>
                      <span :class="{ danger: item.status}">{{ utils.statusFormat(item.status, 'categoryStatus') }}</span>
                    </td>
                    <td>
                      <van-uploader v-model="item.fileList" :show-upload="false" :deletable="false" disabled @click-preview="preview" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <van-cell title="特殊情况备注" :value="utils.isEffectiveCommon(transportForm.logisticsBscInfoVo.remarks)" />
            </div>
          </van-cell-group>
        </van-tab>
        <van-tab name="audit" title="审批意见">
          <workflow-audit page-key="transport_apply" :business-key="transportForm.transportId" :task-id="$route.query.taskId" :variable="{businessId:transportForm.transportId,motif:transportForm.logisticsBscInfoVo.pickUpNo}" @confirmAudit="utils.backReturn($route.query.messageType)" />
        </van-tab>
      </van-tabs>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-button type="info" color="#1373CC" block text="返回" @click="utils.backReturn($route.query.messageType)" />
    </van-row>
  </div>
</template>

<script>
import { Tab, Tabs, Cell, CellGroup, Row, Button, Uploader, Checkbox, CheckboxGroup } from 'vant'
// import fileList from '@/components/file-list'
import WorkflowAudit from '@/components/workflow-audit'
export default {
  components: {
    WorkflowAudit,
    // fileList,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  data () {
    return {
      activeName: 'info',
      transportForm: {
        logisticsBscInfoVo: {
          commonLicenceInfoVoList: []
        }
      }
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.utils.loading()
      this.api.task.logistics.detail(this.$route.query.id).then(result => {
        const files = this._.get(result.data.value, 'logisticsBscInfoVo.commonLicenceInfoVoList', [])
        files.forEach(item => {
          item.fileList = []
          if (item.fileDetailInfoVo) {
            const file = {
              name: item.fileDetailInfoVo.fileName,
              url: item.fileDetailInfoVo.fileUrl,
              ...item.fileDetailInfoVo
            }
            item.fileList.push(file)
          }
        })
        this.transportForm = Object.assign(this.transportForm, result.data.value)
      }).finally(() => {
        this.utils.closeLoading()
      })
    },
    preview (file, detail) {
      const fileTypeArr = file.fileType.split('/')
      if (fileTypeArr[0] !== 'image') {
        window.open(file.url)
      }
    }
  }
}
</script>

<style scoped>

</style>
