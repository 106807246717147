var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main has-fixed-btn" },
    [
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "van-tab",
                { attrs: { name: "info", title: "转运信息" } },
                [
                  _c(
                    "van-cell-group",
                    { attrs: { title: "提单信息" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "提单号",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.pickUpNo
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "合同号（PI/SC）",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.logisticsBscInfoVo.pi
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "订单名称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.logisticsBscInfoVo.orderName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "采购合同",
                          value: _vm.utils.isEffectiveCommon()
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "订单编号",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.logisticsBscInfoVo.orderNo
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm._l(
                    _vm._.get(
                      _vm.transportForm,
                      "logisticsBscInfoVo.logisticsPickUpInfoVoList",
                      []
                    ),
                    function(item) {
                      return _c("van-cell-group", { key: item.id }, [
                        _c(
                          "div",
                          { staticClass: "van-common-right" },
                          [
                            _c("van-cell", {
                              attrs: {
                                title: "合同号（PI/SC）：",
                                value: _vm.utils.isEffectiveCommon(item.pi)
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "柜号：",
                                value: _vm.utils.isEffectiveCommon(
                                  item.cabinetNo
                                )
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "国家：",
                                value: _vm.utils.isEffectiveCommon(item.country)
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "原产地：",
                                value: _vm.utils.isEffectiveCommon(
                                  item.countryOfOrigin
                                )
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "HS编码：",
                                value: _vm.utils.isEffectiveCommon(item.hsCode)
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "货物名称：",
                                value: _vm.utils.isEffectiveCommon(
                                  item.cargoName
                                )
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "箱型：",
                                value: _vm.utils.statusFormat(
                                  item.boxType,
                                  "Box_Type"
                                )
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "件数：",
                                value: _vm.utils.isEffectiveCommon(
                                  item.pieceNumber
                                )
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "实发重量(KG)：",
                                value: _vm.utils.isEffectiveCommon(
                                  item.actualWeight
                                )
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "原币总金额：",
                                value: _vm.utils.moneyFormat(item.money)
                              }
                            })
                          ],
                          1
                        )
                      ])
                    }
                  ),
                  _c(
                    "van-cell-group",
                    { attrs: { title: "船运信息" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "船运公司",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.logisticsBscInfoVo.shippingCompany
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "船名航次",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.logisticsBscInfoVo
                              .oceanVesselVoyNo
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "报关行名称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.logisticsBscInfoVo
                              .customsBrokerName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "起运地",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.logisticsBscInfoVo.originPlace
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "装运口岸",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.logisticsBscInfoVo.shipmentPort
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "目的地",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.logisticsBscInfoVo.destination
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "目的口岸",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.logisticsBscInfoVo.destinationPort
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "申报口岸",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.logisticsBscInfoVo.entryPort
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "预计到港日期",
                          value: _vm.utils.dateFormat(
                            _vm.transportForm.logisticsBscInfoVo
                              .expectedArrivalTime
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "van-cell-group",
                    { attrs: { title: "转运信息" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "船运公司",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.bond
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "转运口岸",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.transportForm.surplus
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "转运单",
                          value: _vm.utils.isEffectiveCommon()
                        }
                      })
                    ],
                    1
                  ),
                  _c("van-cell-group", { attrs: { title: "提单副本材料" } }, [
                    _c(
                      "div",
                      { staticClass: "table goods-table" },
                      [
                        _c("table", { staticClass: "inner-goods-table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("证明材料名称")]),
                              _c("th", [_vm._v("状态")]),
                              _c("th", [_vm._v("附件")])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.transportForm.logisticsBscInfoVo
                                .commonLicenceInfoVoList,
                              function(item) {
                                return _c("tr", { key: item.materialId }, [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.utils.isEffectiveCommon(
                                          item.licenceName
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _c(
                                      "span",
                                      { class: { danger: item.status } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.utils.statusFormat(
                                              item.status,
                                              "categoryStatus"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "td",
                                    [
                                      _c("van-uploader", {
                                        attrs: {
                                          "show-upload": false,
                                          deletable: false,
                                          disabled: ""
                                        },
                                        on: { "click-preview": _vm.preview },
                                        model: {
                                          value: item.fileList,
                                          callback: function($$v) {
                                            _vm.$set(item, "fileList", $$v)
                                          },
                                          expression: "item.fileList"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              }
                            ),
                            0
                          )
                        ]),
                        _c("van-cell", {
                          attrs: {
                            title: "特殊情况备注",
                            value: _vm.utils.isEffectiveCommon(
                              _vm.transportForm.logisticsBscInfoVo.remarks
                            )
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                2
              ),
              _c(
                "van-tab",
                { attrs: { name: "audit", title: "审批意见" } },
                [
                  _c("workflow-audit", {
                    attrs: {
                      "page-key": "transport_apply",
                      "business-key": _vm.transportForm.transportId,
                      "task-id": _vm.$route.query.taskId,
                      variable: {
                        businessId: _vm.transportForm.transportId,
                        motif: _vm.transportForm.logisticsBscInfoVo.pickUpNo
                      }
                    },
                    on: {
                      confirmAudit: function($event) {
                        return _vm.utils.backReturn(
                          _vm.$route.query.messageType
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
        [
          _c("van-button", {
            attrs: { type: "info", color: "#1373CC", block: "", text: "返回" },
            on: {
              click: function($event) {
                return _vm.utils.backReturn(_vm.$route.query.messageType)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }